import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';

export const Address = () => (
  <Container>
    americanas s.a. / CNPJ: 00.776.574/0006-60 / Inscrição Estadual: 85.687.08-5 / Endereço Rua Sacadura Cabral, 102 -
    Rio de Janeiro, RJ - 20081-902 /{' '}
    <TalkToUsLink to="https://www.americanas.com.br/hotsite/atendimento" title="fale com a gente">
      fale com a gente
    </TalkToUsLink>
    {' - atendimento.acom@americanas.com / fale com o RH: falecomrh@americanas.io'}
  </Container>
);

const Container = styled.address`
  font-style: normal;
  font-size: 12px;
  padding: 0;
`;

const TalkToUsLink = styled(Nav)`
  font-size: 12px;
  cursor: pointer;

  transition: all 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primaryColor};
  }
`;
