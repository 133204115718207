import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '@catalogo/ui-americanas-desktop-svg/icon-social-facebook.svg';
import TwitterIcon from '@catalogo/ui-americanas-desktop-svg/icon-social-twitter.svg';
import InstagramIcon from '@catalogo/ui-americanas-desktop-svg/icon-social-instagram.svg';
import YoutubeIcon from '@catalogo/ui-americanas-desktop-svg/icon-social-youtube.svg';
import TiktokIcon from '@catalogo/ui-americanas-desktop-svg/icon-social-tiktok.svg';
import { Nav } from '@catalogo/core-nav';

export const SocialLinks = () => (
  <Container>
    <SocialIcon>
      <SocialLink to="https://www.youtube.com/CanalAmericanas" target="_blank">
        <YoutubeIcon fill="#999" width="25px" height="25px" />
      </SocialLink>
    </SocialIcon>
    <SocialIcon>
      <SocialLink to="https://www.facebook.com/americanas" target="_blank">
        <FacebookIcon fill="#999" width="20px" height="20px" />
      </SocialLink>
    </SocialIcon>
    <SocialIcon>
      <SocialLink to="https://www.instagram.com/americanas" target="_blank">
        <InstagramIcon fill="#999" width="20px" height="20px" />
      </SocialLink>
    </SocialIcon>
    <SocialIcon>
      <SocialLink to="https://twitter.com/americanas" target="_blank">
        <TwitterIcon fill="#999" width="20px" height="20px" />
      </SocialLink>
    </SocialIcon>
    <SocialIcon>
      <SocialLink to="https://www.tiktok.com/@americanas" target="_blank">
        <TiktokIcon fill="#999" width="20px" height="20px" />
      </SocialLink>
    </SocialIcon>
  </Container>
);

const Container = styled.ul`
  text-align: center;
  margin: 0 20px;
  display: flex;
  align-items: center;
`;

const SocialLink = styled(Nav)`
  width: 25px;
  height: 25px;
  padding: 0.3em 0.5em;
`;

const SocialIcon = styled.li`
  display: inline-block;
`;
