import React from 'react';
import styled from 'styled-components';
import ArrowLeft from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import { Nav } from '@catalogo/core-nav';

export const Header = ({ url }) => (
  <Container>
    <NavUI to={url}>
      <BackIcon transform="rotate(90)" />
      mais informações
    </NavUI>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.primaryColor};
  width: 100%;
  color: #fff;
  font-weight: 700;
  padding: 0 8px;
`;

const BackIcon = styled(ArrowLeft)`
  fill: #fff;
  width: 15px;
  margin-right: 8px;
  margin-bottom: 1px;
`;

const NavUI = styled(Nav)`
  padding: 12px 8px;
  color: #fff;
`;
