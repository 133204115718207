import React from 'react';
import styled from 'styled-components';
import Procon from '@catalogo/ui-americanas-desktop-svg/icon-procon-rj.svg';

export const InfoStamps = () => (
  <Container>
    <PaymentInfoList>
      <Text>
        <strong>Formas de pagamento: </strong>
        Mastercard, Visa, Elo, American Express, Aura, Diners Club, Hipercard; boleto bancário; Ame Digital; Pix.
      </Text>
      <Text>
        Todas as regras e promoções são válidas apenas para produtos vendidos e entregues pela Americanas. O preço
        válido será o da finalização da compra. Havendo divergência, prevalecerá o menor preço ofertado.
      </Text>
    </PaymentInfoList>
    <Stamps>
      <div id="armored_website_02">
        <param id="aw_preload" value="true" />
      </div>
      <Procon />
    </Stamps>
  </Container>
);

const Container = styled.div`
  display: flex;
  padding: 12px 0;
  border-top: 1px solid #ccc;
  color: #666;
`;

const Text = styled.li`
  padding: 10px 0;
  font-size: 12px;
`;

const PaymentInfoList = styled.ul`
  width: 74%;
`;

const Stamps = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 24%;
`;
