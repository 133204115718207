import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';

export const FooterItem = ({ menuItem }) => (
  <Container>
    <Link to={menuItem.link}>{menuItem.title}</Link>
  </Container>
);

const Container = styled.li`
  text-align: left;
  padding: 27px 18px 27px 0;
  flex: 4;
  align-items: center;
  display: flex;

  &:nth-child(1) {
    flex: 5;
    padding-right: 54px;
  }

  &:nth-child(3) {
    flex: 3;

    a {
      width: 60px;
    }
  }

  &:not(:nth-child(1)) a {
    max-width: 100px;
  }
`;

const Link = styled(Nav)`
  font-size: 18px;
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primaryColor};
  }
`;
