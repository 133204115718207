import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Arrow from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import { Nav } from '@catalogo/core-nav';

const getHeight = (active, contentRef) => (active ? '0px' : `${contentRef.current.scrollHeight}px`);

export const Accordion = ({ title, content, isLink = false, className }) => {
  const [active, setActiveState] = useState(false);
  const [height, setHeightState] = useState('0px');

  const contentRef = useRef(null);

  if (!title || !content) {
    return null;
  }

  const toggleAccordion = () => {
    const accordionHeight = getHeight(active, contentRef);

    setActiveState(!active);
    setHeightState(accordionHeight);
  };

  const keyEventHandler = key => {
    if (key.code === 'Escape') {
      toggleAccordion();
    }
  };

  return (
    <AccordionSection className={className}>
      <AccordionHeaderWrapper onClick={toggleAccordion} onKeyDown={keyEventHandler}>
        <Title>{title}</Title>
        <Chevron $active={active} width="10" fill="#888" />
      </AccordionHeaderWrapper>
      <AccordionContentWrapper ref={contentRef} height={height}>
        {content.map(item => (
          <AccordionText key={item.id}>
            {isLink ? <AccordionLink to={item.link}>{item.title}</AccordionLink> : <Pre>{item.title}</Pre>}
          </AccordionText>
        ))}
      </AccordionContentWrapper>
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: -1px;
  position: relative;
`;

const AccordionHeaderWrapper = styled.button`
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 18px;
  border: none;
  outline: none;

  &:focus-visible::after {
    content: '';
    border: 2px solid ${({ theme }) => theme.primaryColor};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
`;

const Title = styled.p`
  ${({ theme }) => `
    color: ${theme.grey.darker};
    font-size: ${theme.fontSize.xs};
  `};
  font-weight: 600;
  width: 100%;
  text-align: left;
  padding-right: 60px;
`;

const Chevron = styled(Arrow)`
  margin-left: auto;
  transition: transform 0.6s ease;
  transform: ${({ $active }) => ($active ? 'rotate(-180deg)' : 'none')};
`;

const AccordionContentWrapper = styled.ul`
  background-color: #fff;
  color: #888;
  transition: max-height 0.6s ease;
  overflow: hidden;
  max-height: ${({ height }) => height};
`;

const AccordionText = styled.li`
  ${({ theme }) => `font-size: ${theme.fontSize.xs};`};
  font-weight: 400;
  padding: 0 18px 18px;
  line-height: 1.5em;
`;

const AccordionLink = styled(Nav)`
  text-decoration: underline;
`;

const Pre = styled.div`
  display: block;
  background: inherit;
  border: 0;
  color: #666;
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  padding: 0;
  white-space: pre-wrap;
  word-wrap: normal;
  word-break: normal;
`;
