import React, { useEffect } from 'react';
import styled from 'styled-components';
import Procon from '@catalogo/ui-americanas-desktop-svg/icon-procon-rj.svg';

export const FooterInfo = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//selo.siteblindado.com/aw.js';

    document.head.appendChild(script);
  }, []);

  return (
    <InfoContainer>
      <Text>
        <strong>Formas de pagamento: </strong>
        Mastercard, Visa, Elo, American Express, Aura, Diners Club, Hipercard; boleto bancário; Ame Digital; Pix.
      </Text>
      <Text>
        Todas as regras e promoções são válidas apenas para produtos vendidos e entregues pela Americanas. O preço
        válido será o da finalização da compra. Havendo divergência, prevalecerá o menor preço ofertado.
      </Text>
      <SealsContainer>
        <div id="armored_website_02">
          <param id="aw_preload" value="true" />
        </div>
        <Procon />
      </SealsContainer>
    </InfoContainer>
  );
};

const Text = styled.p`
  padding: 15px 0;
  font-size: 12px;
`;

const InfoContainer = styled.div`
  padding: 20px;
`;

const SealsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
