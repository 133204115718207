import React from 'react';
import styled from 'styled-components';
import { FooterItem } from './footer-item';

export const HighlightedList = ({ highlightList }) => (
  <Container>
    {highlightList.map(item => (
      <FooterItem key={item._id} menuItem={item} />
    ))}
  </Container>
);

const Container = styled.ul`
  display: flex;
  justify-content: space-between;
  text-align: center;
  max-width: 1280px;
  margin: 0 auto;
`;
