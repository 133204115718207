import React from 'react';
import styled from 'styled-components';
import LasaLogo from '@catalogo/ui-americanas-desktop-svg/logo-main.svg';

export const Logo = () => (
  <Container>
    <LasaLogo fill="#f80032" width="163" height="32" />
    <LasaText>passou, cestou.</LasaText>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LasaText = styled.span`
  color: #999;
  margin-left: 11px;
  font-size: 12px;
`;
