import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';

export const MoreInfoOptions = ({ moreInfoList }) => (
  <Container>
    {moreInfoList.map(menuItem => (
      <Wrapper key={menuItem._id}>
        <Title>{menuItem.title.toLowerCase()}</Title>
        <ul>
          {menuItem.children?.map(item => (
            <Item key={item._id}>
              <Link to={item.link}>{item.title.toLowerCase()}</Link>
            </Item>
          ))}
        </ul>
      </Wrapper>
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
`;

const Wrapper = styled.div`
  padding: 12px 0;
  flex: 1;
`;

const Link = styled(Nav)`
  font-size: 14px;

  transition: all 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const Title = styled.p`
  font-size: 16px;
  padding: 16px 0 8px;
  color: #666;
`;

const Item = styled.li`
  padding: 14px 10px 14px 0;
`;
