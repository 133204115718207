import React from 'react';
import compose from 'lodash/fp/compose';
import { withConfig } from '@catalogo/core-connect/config';
import { useFooter } from './hooks/footer';
import { WithFooterProps } from './types';

export const withFooter = (Component: React.FC) => (props: WithFooterProps) => {
  const sitepage = `${props?.config?.brandNameLower}/footer`;
  const { highlightList, moreInfoList } = useFooter(sitepage);

  const componentProps = {
    highlightList,
    moreInfoList,
    ...props,
  };

  return <Component {...componentProps} />;
};

export default compose(withConfig, withFooter);
