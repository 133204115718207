import { useQuery } from '@apollo/client';
import { getFooter } from '@catalogo/service-americanas-footer/src/queries/footer.graphql';
import { getIn } from 'seamless-immutable';

export const useFooter = (sitepage: string) => {
  const { data } = useQuery(getFooter, {
    variables: {
      sitepage,
    },
  });

  const highlightList = getIn(data, ['publication', 'component', 'children', '0', 'children'], []);
  const moreInfoList = getIn(data, ['publication', 'component', 'children', '1', 'children'], []);

  return { data, moreInfoList, highlightList };
};
